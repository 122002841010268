import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Skeleton, PageHeader, Button, Icon } from "antd";

import Title from "antd/lib/typography/Title";
import ReactToPrint from "react-to-print";

import { getDefaultConfig } from "utils/APIUtils";

import QuizCard from "./QuizCard";
import {
  getQuizRandomSeed,
  getCourseTitle,
  getCourseSymbol,
} from "./workbookUtil";
import queryString from "query-string";
import ClassroomApis from "utils/classroomApis";

import "components/Quiz/style/index.scss";
import "components/Quiz/css/_layout.scss";
import "components/Quiz/css/Quiz.scss";
import "katex/dist/katex.min.css";

import "./index.scss";
import Axios from "utils/request";
import QuizDescCard from "./QuizDescCard";
import QuizAnswerCard from "./QuizAnswerCard";

const WORKBOOK = "workbook";
const SOLVINGBOOK = "solvingbook";
export class LectureWorkBookPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      lecture: null,
      quetureQuizList: [],
      viewType: WORKBOOK,
    };
  }

  componentDidMount() {
    const { id, lectureId } = this.props.match.params;
    const { viewType } = queryString.parse(this.props.location.search);

    if (viewType && viewType === SOLVINGBOOK) {
      this.setState({ viewType: SOLVINGBOOK }, () => {
        this.loadWorkbookData(id, lectureId);
      });
    } else {
      this.loadWorkbookData(id, lectureId);
    }
  }

  loadWorkbookData = async (id, lectureId) => {
    this.setState({ loading: true });
    const curLecture = await ClassroomApis.getLecture(id, lectureId);
    let quetureQuizList = [];
    let quetureSeq = 0;
    let lastQuetureId = "";
    let lastQuetureTitle = "";

    for (let i in curLecture.sections) {
      let s = curLecture.sections[i];
      if (s.type === "QUETURE") {
        quetureSeq++;
        lastQuetureId = s.quetureId;
        lastQuetureTitle = s.quetureTitle;
      }
      if (s.type === "QUIZ") {
        if (s.quetureId === lastQuetureId) {
          quetureQuizList.push({
            quetureSeq: quetureSeq,
            quetureId: s.quetureId,
            quetureTitle: lastQuetureTitle,
            quizIds: s.quiz.map((e) => e.quizId), // NOTE: stored as default value; to be overrided
            // quizIds: ["12732455874204045", "12713578167537831", "12713497888559264"], // test coloring & counting quiz
            quizAuxType: s.auxType, // QUIZ8Y or null
          });
        }
      }
    }

    let quetureQuizIndex = 0;
    for (const quetureQuiz of quetureQuizList) {
      const quetureQuizRes = await Axios.get(
        `/content/v1/quetures/${quetureQuiz.quetureId}`,
        {
          ...getDefaultConfig(),
        }
      );
      if (quetureQuizRes) {
        const q = quetureQuizRes;
        if (
          quetureQuiz.quizAuxType === "QUIZ8Y" &&
          q.campusHomework8y &&
          q.campusHomework8y.length > 0
        ) {
          quetureQuiz.quizIds = q.campusHomework8y;
        } else if (q.campusHomework && q.campusHomework.length > 0) {
          quetureQuiz.quizIds = q.campusHomework;
        } else if (
          q.workbooks &&
          q.workbooks["10"] &&
          q.workbooks["10"].length > 0
        ) {
          quetureQuiz.quizIds = q.workbooks["10"];
        } else if (
          q.workbooks &&
          q.workbooks["9"] &&
          q.workbooks["9"].length > 0
        ) {
          quetureQuiz.quizIds = q.workbooks["9"];
        }

        quetureQuiz.quetureTitle = q.title;
      }

      // load quiz by quetureQuiz.quizIds
      if (quetureQuiz.quizIds.length > 0) {
        let quizIndex = 0;
        quetureQuiz["quizInfoList"] = [];
        quetureQuiz["quizSolvingList"] = [];
        for (const quizId of quetureQuiz.quizIds) {
          try {
            const randomSeed = getQuizRandomSeed(
              curLecture.lectureId,
              quetureQuizIndex,
              quizIndex
            );

            const quizRes = await Axios.get(
              `/quiz/v2/quizzes/${quizId}${
                randomSeed ? "?randomSeed=" + randomSeed : ""
              }`,
              {
                ...getDefaultConfig(),
              }
            );

            if (quizRes) {
              const quiz = quizRes;
              quetureQuiz.quizInfoList[quizIndex] = quiz;

              try {
                const solvingRes = await Axios.post(
                  `/quiz/v2/quizzes/${quizId}/solve`,
                  { answers: [], vars: quiz.quiz.vars }
                );

                if (solvingRes) {
                  quetureQuiz.quizSolvingList[quizIndex] = solvingRes;
                }
              } catch (solvingError) {
                console.error(`Error solving quiz ${quizId}:`, solvingError);
                // Continue with next quiz even if solving fails
              }
            }
          } catch (quizError) {
            console.error(`Error loading quiz ${quizId}:`, quizError);
            // Continue with next quiz even if one fails
          }

          quizIndex++;
        }
      }
      quetureQuizIndex++;
    }

    this.setState({
      lecture: curLecture,
      quetureQuizList: quetureQuizList,
      loading: false,
    });
  };

  render() {
    const { loading, lecture, quetureQuizList } = this.state;

    if (loading || !lecture) {
      return "loading...";
    }

    let courseTitle = getCourseTitle(lecture.courseTitle);
    // let color = getCourseColor(lecture.courseTitle);
    let cs = getCourseSymbol(lecture.courseTitle);
    console.log("quetureQuizList: ", quetureQuizList);
    return (
      <Skeleton loading={loading} active>
        {this.state.viewType === WORKBOOK && (
          <>
            <PageHeader
              title={
                <Title level={2} style={{ marginBottom: 0 }}>
                  {courseTitle} {lecture.title} 워크북 문제집
                </Title>
              }
              extra={[
                <Button
                  key={1}
                  onClick={() =>
                    // this.props.history.push(
                    //   `/admin/class/detail/${this.props.match.params.id}/lecture-solvingbook/${this.props.match.params.lectureId}`
                    // )
                    this.setState({ viewType: SOLVINGBOOK })
                  }
                >
                  <Icon type="double-right" />
                  해설집
                </Button>,
                <span key="2">
                  <ReactToPrint
                    trigger={() => (
                      <Button>
                        <Icon type="printer" />
                        프린트
                      </Button>
                    )}
                    content={() => this.workbookComponentRef}
                    documentTitle={`${courseTitle} ${lecture.title} 워크북 문제집`}
                  />
                </span>,
              ]}
            />
            <div className="admin-page-print-content">
              <div
                style={{ position: "relative", padding: "0px" }}
                ref={(el) => (this.workbookComponentRef = el)}
              >
                {quetureQuizList.map((quetureQuiz, quetureQuizIndex) => (
                  <div
                    key={`queture-quiz-${quetureQuizIndex}`}
                    className={"queture-quiz-wrap break-page"}
                  >
                    <div
                      className={`queture-quiz-title workbook-background-${cs}`}
                    >
                      <div className={`index workbook-color-${cs}`}>
                        {lecture.title.replace("회차", "")}
                        <span style={{ fontSize: "26px" }}>
                          회차 #{quetureQuiz.quetureSeq}
                        </span>
                      </div>
                      <div className={`title`}>{quetureQuiz.quetureTitle}</div>
                    </div>

                    {!quetureQuiz?.quizInfoList && (
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: 20,
                          fontWeight: 500,
                          padding: "20px 20px 30px",
                        }}
                      >
                        인트로 영상은 문제 없습니다.
                      </div>
                    )}
                    {quetureQuiz?.quizInfoList && (
                      <div className="quiz-card-list">
                        {quetureQuiz?.quizInfoList?.map((quiz, index) => (
                          <QuizCard
                            key={`quiz-card-${index}`}
                            quizData={quiz}
                            solvedData={quetureQuiz.quizSolvingList[index]}
                            current={quiz.quizId}
                            index={index}
                            currentQuizIndex={index}
                            showTrueAnswers={false}
                            forWorkbook={true}
                            randomSeed={getQuizRandomSeed(
                              lecture.lectureId,
                              quetureQuizIndex,
                              index
                            )}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
        {this.state.viewType === SOLVINGBOOK && (
          <>
            <PageHeader
              title={
                <Title level={2} style={{ marginBottom: 0 }}>
                  {courseTitle} {lecture.title} 워크북 해설집
                </Title>
              }
              extra={[
                <Button
                  onClick={() =>
                    // this.props.history.push(
                    //   `/admin/class/detail/${this.props.match.params.id}/lecture-workbook/${this.props.match.params.lectureId}`
                    // )
                    this.setState({ viewType: WORKBOOK })
                  }
                >
                  <Icon type="double-right" />
                  문제집
                </Button>,
                <span key="2">
                  <ReactToPrint
                    trigger={() => (
                      <Button>
                        <Icon type="printer" />
                        프린트
                      </Button>
                    )}
                    content={() => this.solvingBookComponentRef}
                    documentTitle={`${courseTitle} ${lecture.title} 워크북 해설집`}
                  />
                </span>,
              ]}
            />
            <div className="admin-page-print-content">
              <div
                style={{ position: "relative", padding: "0px" }}
                ref={(el) => (this.solvingBookComponentRef = el)}
              >
                {quetureQuizList.map((quetureQuiz, quetureQuizIndex) => (
                  <div
                    className={
                      quetureQuizIndex >= 1
                        ? "queture-quiz-wrap break-page"
                        : "queture-quiz-wrap"
                    }
                  >
                    <table
                      className={`queture-quiz-solving-page-header workbook-color-${cs}`}
                    >
                      <tbody>
                        <tr>
                          <td className="solving-page-header-detail">&nbsp;</td>
                          <td className="solving-page-header-title">
                            정답과 해설
                          </td>
                          <td className="solving-page-header-dot">
                            · · · · · · · · · · · · · · · · · · · · · · · · · ·
                            · · · · · · · · · · · · · · · · · · · · · · · · · ·
                            · · · · · · · · ·
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div className="queture-quiz-solving-title">
                      <div className={`index workbook-color-${cs}`}>
                        {lecture.title.replace("회차", "")}
                        <span style={{ fontSize: "30px" }}>회차</span>
                      </div>
                      <div className={`numberTag workbook-solving-tag-${cs}`}>
                        # {quetureQuiz.quetureSeq}
                      </div>
                      <div className="title">{quetureQuiz.quetureTitle}</div>
                    </div>
                    {!quetureQuiz?.quizInfoList && (
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: 20,
                          fontWeight: 500,
                          padding: "20px 20px 30px",
                        }}
                      >
                        인트로 영상은 문제 없습니다.
                      </div>
                    )}
                    {quetureQuiz?.quizInfoList && (
                      <>
                        <div className="queture-quiz-heading-speechbubble">
                          정답
                        </div>
                        <div className="queture-quiz-solving-answer">
                          {quetureQuiz?.quizInfoList?.map((quiz, index) => (
                            <div className="queture-quiz-solving-answer-item">
                              <table>
                                <tbody>
                                  <tr>
                                    <td>
                                      <div className="queture-quiz-solving-answer-number">
                                        {index + 1}.
                                      </div>
                                    </td>
                                    <td>
                                      <div className="queture-quiz-solving-answer-value">
                                        <QuizAnswerCard
                                          quizInfo={quiz}
                                          solvedData={
                                            quetureQuiz.quizSolvingList[index]
                                          }
                                          quizId={quiz.quizId}
                                          quizIndex={index}
                                          forWorkbook={true}
                                          randomSeed={getQuizRandomSeed(
                                            lecture.lectureId,
                                            quetureQuizIndex,
                                            index
                                          )}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          ))}
                        </div>

                        <div className="queture-quiz-heading-speechbubble">
                          해설
                        </div>
                        {quetureQuiz?.quizInfoList?.map((quiz, index) => (
                          <QuizDescCard
                            quizInfo={quiz}
                            solvedData={quetureQuiz.quizSolvingList[index]}
                            quizId={quiz.quizId}
                            quizIndex={index}
                            forWorkbook={true}
                            randomSeed={getQuizRandomSeed(
                              lecture.lectureId,
                              quetureQuizIndex,
                              index
                            )}
                          />
                        ))}
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </Skeleton>
    );
  }
}

export default withRouter(LectureWorkBookPage);
